import DefaultLayout from "@nitro/gatsby-theme-harvia/src/components/layouts/DefaultLayout"
import Page404 from "@nitro/gatsby-theme-harvia/src/components/pages/Page404"
import PageContextProvider from "@nitro/gatsby-theme-harvia/src/context/PageContext"
import { graphql, PageProps } from "gatsby"
import React from "react"

const Page = (props: PageProps<Queries.Page404Query>) => {
  // console.debug("Rendering Page", props)

  return (
    <PageContextProvider value={{ locale: "en", localePim: "en-US", fallbackLocales: [] }}>
      <DefaultLayout mainMenu={props.data.website?.mainMenu} footer={props.data.website?.footer} seoMetaTags={{}}>
        <Page404 locale="en" />
      </DefaultLayout>
    </PageContextProvider>
  )
}

export default Page

export const query = graphql`
  query Page404 {
    website: datoCmsWebsite {
      mainMenu {
        ...DatoMainNavTree
      }
      footer {
        ...DatoFooter
      }
    }
  }
`
