import { Link } from "gatsby"
import React from "react"
import useDatoMicrocopy from "../../hooks/useDatoMicrocopy"
import type { DatoLocale } from "../../types"
import Button from "../atoms/Button"
//import * as styles from "./Paeg404.module.scss"

export type Paeg404Props = { locale: DatoLocale }

const Paeg404: React.FC<Paeg404Props> = props => {
  const t = useDatoMicrocopy("common", props.locale)
  //console.log('Rendering Paeg404', props)
  return (
    <div className="layout-container" style={{ paddingTop: 100, paddingBottom: 100 }}>
      <h1>404</h1>
      <Link to={`/${props.locale ? props.locale : ""}`}>
        <Button label={t("frontPage", "Front page")} />
      </Link>
    </div>
  )
}

export default Paeg404
